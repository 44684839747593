.project__item {
  position: relative;
  width: 20%;
  height: 25vh;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
  cursor: pointer;
  transition: 1s ease-in-out;
  margin: 0 15px;
}

.project__item:nth-child(1) {
  margin-left: 0;
  margin-right: 15px;
}

.project__item:last-child {
  margin-left: 15px;
  margin-right: 0;
}

.project__overlay--hidden {
  /* display: none; */
  position: absolute;
  bottom: -25vh;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 25vh;
  transition: 0.1s ease-in-out;
  z-index: 2;
  background: linear-gradient(to top, black, #444);
}
.project__overlay--visible {
  position: absolute;
  /* display: block; */
  background: linear-gradient(to top, black, #444);
  opacity: 0.5;
  width: 100%;
  height: 25vh;
  z-index: 2;
  bottom: 0;
  margin: 0;
  padding-bottom: 15px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: 0.1s ease-in-out;
}

.project__logo {
  color: white;
  font-size: 1.5rem;
}
