.hero {
height: 100vh;
  background: dodgerblue;
  position: relative;
}
.hero__overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: black;
  opacity: .6;
}
.hero img {
  height: 100%;
  width: 100%;
}

.hero__more {
    color: green;
    position: absolute;
    bottom: 30px;;
    left: 50%;
}
